$web-font-path: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700' !default;
@import url($web-font-path);
@import './colours.scss';

// Bootstrap Setup
$font-family-sans-serif:
// Safari for OS X and iOS (San Francisco)
    'Poppins',
    // Chrome < 56 for OS X (San Francisco)
    'Poppins',
    // Windows
    'Poppins',
    // Android
    'Poppins',
    // Basic web fallback
    'Poppins',
    Arial, sans-serif;

$enable-negative-margins: true;

@mixin shadow($width: 4px) {
    border-style: solid;
}

@mixin font-bold-alternative($color: lighten($dark-carbon, 30%)) {
    text-shadow: 0 0 0.01px $color;
}

$link-color: $dark-green;
$body-color: $font-color;
a {
    color: $link-color;
}

$font-weight-normal: 300;

// Bootstrap and Icon Imports
@import 'bootstrap/scss/bootstrap.scss';
@import '~font-awesome/css/font-awesome.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '../_common/style/dropdown-icon.scss';
@import '../_common/style/tooltips.scss';
@import '../_common/style/zoom-in-mobile-fix.scss';

.background-primary {
    background-color: $primary-background-color;
}

// Cards
.card-icon {
    z-index: 1000;
}

.card-overview {
    margin-bottom: 16px !important;
    border: none;
    border-radius: 8px;

    .card-header {
        border-radius: 8px !important;
        border-bottom: 0;
    }
}

// Badge
.badge-primary {
    background-color: #f1d1d1;
    color: $primary;
}

// Hero image
.hero-image {
    background-size: cover;
    background-position: center;
    max-height: 835px;
    object-fit: cover;
}

.hero-image-small {
    background-repeat: no-repeat;
    background-color: $primary-background-color;
    max-height: 200px;
    min-height: 200px;
}

@include media-breakpoint-up(xs) {
    .hero-image-small {
        background-size: cover;
        background-position: center right;
    }
}

@include media-breakpoint-up(sm) {
    .hero-image-small {
        background-size: cover;
        background-position: center center;
    }
}

@include media-breakpoint-up(md) {
    .hero-image-small {
        background-size: cover;
        background-position: center center;
    }
}
@include media-breakpoint-up(lg) {
    .hero-image-small {
        background-size: auto;
        background-position: center bottom;
    }
}

@include media-breakpoint-up(xl) {
    .hero-image-small {
        background-size: auto;
        background-position: center bottom;
    }
}

@include media-breakpoint-up(xs) {
    .header-image-logo {
        width: 60%;
    }
}

@include media-breakpoint-up(sm) {
    .header-image-logo {
        width: 60%;
    }
}

@include media-breakpoint-up(md) {
    .header-image-logo {
        width: 40%;
    }
}

@include media-breakpoint-up(lg) {
    .header-image-logo {
        width: 30%;
    }
}

@include media-breakpoint-up(xl) {
    .header-image-logo {
        width: 25%;
    }
}

// Buttons =====================================================================
.btn-group-toggle {
    > .btn,
    > .btn-group > .btn {
        input[type='radio'],
        input[type='checkbox'] {
            position: absolute;
            clip: rect(0, 0, 0, 0);
            pointer-events: none;
        }
    }
}

.btn {
    @include shadow();
}

[class*='btn-outline'] {
    border-top-width: 1px;
}

[disabled].btn-primary {
    color: $secondary !important;
}

.btn {
    &-group {
        display: block;
    }
    &-primary,
    &-primary:hover {
        color: $secondary;
        border-color: darken($primary, 5%);
    }
    &-primary:active,
    &-primary:checked {
        color: $secondary !important;
    }
    &-outline-primary {
        border-color: darken($primary, 5%);
    }
    &-outline-primary.active,
    &-outline-primary:hover {
        color: $secondary;
        border-color: darken($primary, 5%);
    }
    &-link {
        text-decoration: none;
    }
    &-secondary {
        color: $font-color;
        border-color: $secondary-border-color;
    }
    &-secondary:active,
    &-secondary:checked {
        color: $font-color !important;
        background-color: darken($light, 10%) !important;
        border-color: darken($light, 10%) !important;
    }
    &-secondary:hover {
        color: $font-color;
        background-color: darken($light, 5%);
        border-color: darken($light, 10%);
    }
    &-success {
        border-color: darken($success, 5%);
    }

    &-info {
        border-color: darken($info, 5%);
    }

    &-danger {
        border-color: darken($danger, 5%);
    }

    &-warning {
        border-color: darken($warning, 5%);
    }

    &-light {
        border-color: darken($light, 5%);
    }

    &-dark {
        border-color: darken($dark, 5%);
    }
    &-block {
        width: 100%;
    }
}
.btn-link {
    color: $dark-green;
}

.cta-rooftest {
    .btn-link {
        border-color: darken($green, 5%);
    }
}

.btn-group-vertical {
    .btn + .btn {
        &:hover {
            margin-top: -1px;
            border-top-width: 1px;
        }

        &:active {
            margin-top: -1px;
            border-top-width: 2px;
        }
    }
}

.roof-pitch svg {
    fill: darken($green, 5%);
    width: 50px;
}

.roof-pitch-icon {
    width: 30%;
    min-width: 50px;
}

// Default Font
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 700;
    font-family: $font-family-sans-serif;
}

// Dropdown

.dropdown {
    display: flex;

    .fa-chevron-down {
        transition: transform 0.5s;
    }

    &.show {
        .fa-chevron-down {
            transform: rotate(180deg);
        }

        @include media-breakpoint-up(lg) {
            .fa-chevron-down {
                transform: none;
            }
        }
    }
}

.dropdown-menu {
    padding: 0.5rem 0;
    margin: 0;
    border: none;
    border-radius: unset;

    @include media-breakpoint-up(lg) {
        min-width: 100%;
        left: calc(50% + 1.5rem);
        transform: translateX(calc(-50% - 0.75rem));
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2) inset,
            0px 4px 10px 2px rgba(0, 0, 0, 0.1);
        padding: 1rem 0;
    }
}

.dropdown-toggle::after {
    all: unset;
}

.dropdown-item {
    font-size: 0.875rem;
    color: $black;

    &:hover,
    &:active {
        @include font-bold-alternative;
        color: $black;
        background-color: transparent;
    }
}

// Business Specific Changes

@mixin fake-underline($color: $primary, $top: 84%, $bottom: 90%, $width: 80%) {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) $top,
        $color $top + 0.1%,
        $color $bottom,
        rgba(0, 0, 0, 0) $bottom + 0.1%,
        rgba(0, 0, 0, 0)
    );
    background-size: $width;
    background-repeat: no-repeat;
    background-position: center;
    text-decoration: none;
}

.navbar-light .navbar-nav .nav-link {
    border: unset;
    outline: unset;
    background: transparent;
    color: $font-color;
    font-weight: 400;
    font-size: 1.05rem;

    span {
        white-space: normal;
    }
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .dropdown.active .dropdown-toggle {
    @include font-bold-alternative;

    @include media-breakpoint-up(lg) {
        @include fake-underline($primary, 95%, 100%);
    }
}

.navbar-light .navbar-nav .dropdown-item.active {
    @include font-bold-alternative;
    color: $font-color;
    background-color: transparent;
}

.navbar-toggler {
    border: none;

    &:focus {
        outline: none;
    }

    .bar {
        width: 2.4rem;
        height: 0.3rem;
        margin: 5px 0;
        background-color: $black;
        transition: transform 0.5s, opacity 0.5s;
        border-radius: 5px;
    }

    &[aria-expanded='true'] {
        .bar1 {
            transform: rotate(-45deg) translate(-0.3rem, 0.5rem);
        }
        .bar2 {
            opacity: 0;
        }
        .bar3 {
            transform: rotate(45deg) translate(-0.3rem, -0.7rem);
        }
    }
}

.black-trans-bg {
    background-color: rgba(0, 0, 0, 0.5);
}

ngb-accordion h5 button.btn {
    white-space: normal !important;
    text-align: left;
}

.tooltip-icon {
    cursor: pointer;
    border: none;
    outline: none !important;
    background: none;
}

.tooltip-icon:hover {
    color: $primary !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

// Link Box //
a {
    text-decoration: none;
}

.link-box a {
    color: $body-color;
    text-decoration: none;
}

.link-box .card-title {
    border-color: $primary;
}

.link-box .fa {
    margin-top: 2px;
}

.link-box:hover .fa {
    color: $link-color;
}

.link-box:hover .card-title {
    color: $link-color;
    transition: all 0.55s ease-in-out;
}

// Hoverable //

.hoverable {
    box-shadow: none;
    transition: all 0.55s ease-in-out;
}

.hoverable:hover {
    box-shadow: $box-shadow-lg;
}

/* Stepper css */

.sticky-container {
    background: $white;
}

.step-item {
    flex: 1 1 0px;
    position: relative;
    text-align: center;
}

.step-item:not(:first-child)::before {
    background: $gray-600;
    content: '';
    height: 2px;
    left: -50%;
    position: absolute;
    top: 9px;
    width: 100%;
}

.step-item a {
    display: inline-block;
    padding: 20px 10px 0;
    text-decoration: none;
}

.steps-icon {
    border-radius: 50%;
    height: 2rem;
    position: absolute;
    top: -0.4rem;
    left: calc(50% - 1rem);
    width: 2rem;
    z-index: 1;
    padding-top: 0.3rem;
    padding-right: 0.05rem;
    border-style: Solid;
    border-width: 2px;
}

.not-clickable {
    cursor: default;
}

.notdone {
    background: $white;
    color: $gray-600;
    border-color: $gray-600;
}

.doing {
    background: $primary;
    color: $white;
    border-color: $primary;
}

p.doing {
    background: none;
    color: $primary;
    border-color: none;
}

.done {
    background: $white;
    color: $primary;
    border-color: $primary;
}

.step-item:hover .done {
    background: $light;
    color: $primary;
    border-color: $primary;
}

.step-item:hover p.done {
    background: none;
    color: $primary;
    border-color: none;
}

label {
    margin-bottom: 0.1rem !important;
    font-weight: bold;
}

.custom-control-label {
    font-weight: normal;
}

label p:last-child,
li p:last-child,
div.alert p:last-child,
div.card-body p:last-child {
    margin-bottom: 0;
}

/* Font scaling (experimental) */

html {
    font-size: 1rem;
}

@include media-breakpoint-up(xs) {
    html {
        font-size: 0.875rem;
    }
}

@include media-breakpoint-up(lg) {
    html {
        font-size: 1rem;
    }
}

/* Checkboxes & Radio buttons */

.is-invalid {
    fill: $danger;
    color: $danger;
}

.form-control:hover {
    border-color: darken($input-border-color, 30%);
}

.form-control.is-invalid:hover {
    border-color: darken($danger, 20%);
}

.custom-control-label::before {
    background-color: $white;
    border: $gray-500 solid 2px;
    width: 20px;
    height: 20px;
    margin-top: 4px;
}

.custom-control-label::after {
    width: 20px;
    height: 20px;
    margin-top: 4px;
}

.custom-control-label:hover::before {
    border-color: darken($input-border-color, 30%);
}

/* Edge doesnt properly scale svg's */
svg {
    // width: 100%;
}

/* Cookiebar */

a#CybotCookiebotDialogBodyLevelButtonAccept {
    display: inline-block !important;
    background-color: $success !important;
    border: 1px solid $success !important;
    border: 1px solid transparent !important;
    border-radius: 0.25rem !important;
    font-size: 1rem !important;
}

a#CybotCookiebotDialogBodyLevelButtonAccept:focus {
    rgba: ($component-active-bg, 0.25) !important;
}

a#CybotCookiebotDialogBodyLevelButtonAccept:hover {
    rgba: ($component-active-bg, 0.25) !important;
}

#CybotCookiebotDialog {
    font-family: $font-family-sans-serif !important;
}

#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentTitle {
    font-family: $font-family-sans-serif !important;
}

/* Custom style to make block buttons behave better
-------------------------------------------------- */
@media (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }
    input[type='submit'].btn-xs-block,
    input[type='reset'].btn-xs-block,
    input[type='button'].btn-xs-block {
        width: 100%;
    }
    .btn-block + .btn-xs-block,
    .btn-xs-block + .btn-block,
    .btn-xs-block + .btn-xs-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }
    input[type='submit'].btn-sm-block,
    input[type='reset'].btn-sm-block,
    input[type='button'].btn-sm-block {
        width: 100%;
    }
    .btn-block + .btn-sm-block,
    .btn-sm-block + .btn-block,
    .btn-sm-block + .btn-sm-block {
        margin-top: 0.5rem;
    }

    div.reactive-right-border {
        border-end: 1px solid $gray-300;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }
    input[type='submit'].btn-md-block,
    input[type='reset'].btn-md-block,
    input[type='button'].btn-md-block {
        width: 100%;
    }
    .btn-block + .btn-md-block,
    .btn-md-block + .btn-block,
    .btn-md-block + .btn-md-block {
        margin-top: 0.5rem;
    }

    div.reactive-right-border {
        border-end: 1px solid $gray-300;
    }
}
@media (min-width: 1200px) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }
    input[type='submit'].btn-lg-block,
    input[type='reset'].btn-lg-block,
    input[type='button'].btn-lg-block {
        width: 100%;
    }
    .btn-block + .btn-lg-block,
    .btn-lg-block + .btn-block,
    .btn-lg-block + .btn-lg-block {
        margin-top: 0.5rem;
    }

    div.reactive-right-border {
        border-end: 1px solid $gray-300;
    }
}

div.counter p.lead {
    margin: 0;
}

.table-bordered {
    td {
        border: 1px solid $gray-200;
    }
}

.alert-survey {
    $survey-btn: #f8f8f8;
    color: $white;
    background-color: $primary;
    border-color: $primary;

    .btn {
        color: $black;
        background-color: $survey-btn;
        border-color: $survey-btn;
    }
}
.register-border {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0.5rem;
    margin-top: 1.5rem;
}

.bg-soft-gray {
    background: $lightgray;
}

.next-steps-proposal {
    ol {
        padding-left: 1rem;
        li {
            margin-bottom: 1.5rem;
            padding-left: 0.5rem;
        }
    }
}
a.attachment-link {
    text-decoration: underline;
}

// Youtube Play button
.play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
}

.play-button:hover {
    background-color: #218838;
    opacity: 1;
}

.play-button:before {
    content: '';
    border-style: solid;
    border-width: 15px 0 15px 26px;
    border-color: transparent transparent transparent #fff;
}
.play-button {
    cursor: pointer;
}
.play-button,
.play-button:before {
    position: absolute;
}
.play-button,
.play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

@include media-breakpoint-up(lg) {
    .border-divider {
        border-color: #b6b6af;
        border-style: none solid none none;
        border-width: 1px;
    }
}

//Info Block
@include media-breakpoint-up(md) {
    .info-img {
        border-radius: 10px;
        max-height: 320px;
        object-fit: cover;
    }

    .info-content {
        background-color: $off-white;
        border-radius: 10px;

        a {
            color: $dark-green;
            padding: 0;
        }
    }
}

@include media-breakpoint-down(md) {
    .info-img {
        border-radius: 10px 10px 0px 0px;
        max-height: 170px;
        object-fit: cover;
    }

    .info-content {
        background-color: $off-white;
        border-radius: 0px 0px 10px 10px;

        a {
            padding: 0;
        }
    }
}

@media (min-width: 1440px) {
    .nav-padding {
        padding: 0vw 10vw 0vw 10vw;
    }
}

@media (max-width: 1439px) {
    .nav-padding {
        padding: 0vw 5vw 0vw 5vw;
    }
}

@media (min-width: 1440px) {
    .hero-left-padding {
        padding: 5vw 5vw 5vw 10vw;
    }
}

@media (max-width: 1439px) {
    .hero-left-padding {
        padding: 5vw 5vw 5vw 5vw;
    }
}
