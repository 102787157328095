// Brand colors
$deep-forest: #103328;
$valley-green: #57b030;
$ocean-blue: #3e4ac5;
$golden-hour: #e8b500;
$morning-dew: #ebebe1;
$dark-carbon: #0c0c09;
$early-mist: #ffffff;

// Font & border colors
$font-color: #494941;
$footer-color: #161616;
$border-color: #ced4da;
$secondary-border-color: #d9d9cb;
$input-border-color: $secondary-border-color !default;

$green: #38841a;
$dark-green: #346c1f;
$ligth-green: #e0f5d2;
$off-white: #fafaf6;
$lightgray: #f4f4f0;

$success: #218838;
$warning: #e67f00;
$white: $early-mist;
$black: $dark-carbon;

$primary: $green;
$secondary: $early-mist;
$light: $morning-dew;
$primary-background-color: $morning-dew;
